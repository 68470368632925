// external imports
import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { fragments } from "../lib/fragments"

// helpers
import { up, addResponsivity } from "../lib/styles"
import { extractNodesFromDataApplyLangs } from "../lib/helpers"
import { applyLang, applyLangSingle } from "../lib/intl"
import injectIntl from "../lib/intl/injectIntl"

// components
import T from "../lib/intl/T"
import {
  SEO,
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Listing,
  WaveImage,
  ChevronIcon,
  Video,
} from "../components"

// assets
const Line = styled.div`
  height: 0;
  border-bottom: 2px dashed #00b3a6;
  opacity: 0.3;
  width: 100%;
`

const JobRow = styled(Row)`
  min-height: 60px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
`

// custom styled components

class Career extends React.Component {
  video = null // playVideo() pauseVideo()

  render() {
    const {
      location: { pathname },
      intl: { language, t },
      data: { videoThumbnailImage },
    } = this.props

    const { offers } = extractNodesFromDataApplyLangs(this.props.data, language)

    return (
      <>
        <SEO
          title={t("Career - meta title")}
          description={t("Career - meta desc")}
          pathname={pathname}
        />

        <Gap.Top />
        <Row justifyContent="space-between" responsive bp="menu">
          <Col maxWidth="1096px">
            <Title.PageTitle>
              <T>Kariéra</T>
            </Title.PageTitle>
            <Gap gap="22px" mobileGap="24px" />
            <Col maxWidth="586px">
              <Text.Intro>
                <T>
                  Staňte se součástí Formé rodiny. Hledáme lidi, kteří nám
                  pomohou měnit osudy našich klientů.
                </T>
              </Text.Intro>
              <Gap gap="40" mobileGap="24px" />
              <Text>
                <T raw>career - info</T>
              </Text>
            </Col>
          </Col>
          <Gap gap="24px" />
          <Col>
            <Video videoId="5exAySvW--E" thumbnail={videoThumbnailImage} />
          </Col>
        </Row>
        <Gap gap="80px" />
        {offers && offers.length > 0 && (
          <Row justifyContent="center">
            <Col width="100%" maxWidth="818px" alignItems="flex-start">
              <WaveImage />
              <Gap gap="24px" />
              <Title.MediumTitle>
                <T>Seznam aktuálních volných pozic:</T>
              </Title.MediumTitle>
              <Gap gap="38px" mobileGap="24px" />
              <Line />

              {offers.map(({ title, slug }) => (
                <Fragment key={slug}>
                  <Link width="100%" to={`${Link.CAREER}/${slug}`}>
                    <JobRow>
                      <Title.SmallestTitle fontSize="22px">
                        {title}
                      </Title.SmallestTitle>
                      <ChevronIcon.Career />
                    </JobRow>
                  </Link>
                  <Line />
                </Fragment>
              ))}
            </Col>
          </Row>
        )}
        <Gap gap="80px" mobileGap="24px" />
      </>
    )
  }
}

export default injectIntl(Career)

export const pageQuery = graphql`
  fragment VideoThumbnailImage on File {
    image: childImageSharp {
      gatsbyImageData(
        width: 416
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }

  {
    offers: allJoboffers(
      sort: { fields: createdAt, order: DESC }
      filter: { state: { eq: "published" } }
    ) {
      edges {
        node {
          ...JobOfferFragment
        }
      }
    }
    videoThumbnailImage: file(
      relativePath: { eq: "images/career-video-thumbnail.png" }
    ) {
      ...VideoThumbnailImage
    }
  }
`
